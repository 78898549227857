import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IconModule } from '../../components/icon/icon.module';
import { ModalRootComponent } from './modal-root.component';

@NgModule({
  imports: [
    CommonModule,
    IconModule,
  ],
  exports: [
    ModalRootComponent,
  ],
  declarations: [
    ModalRootComponent,
  ],
})
export class ModalRootModule {}
