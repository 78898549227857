//#region Imports

import { DOCUMENT } from '@angular/common';
import { ComponentFactory, ComponentFactoryResolver, Inject, Injectable, Type } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { generateRandomString } from '../../utils/random-string';

//#endregion

@Injectable({
  providedIn: 'root',
})
export class ModalService {

  //#region constructor

  constructor(
    @Inject(DOCUMENT)
    protected readonly document: Document,
    protected readonly componentFactoryResolver: ComponentFactoryResolver,
  ) {
    this.modalsList.subscribe(list => {
      if (!this.document.body)
        return;

      this.document.body.style.overflowY = list.length > 0 ? 'hidden' : 'auto';
    });
  }

  //#endregion

  //#region Subject

  public modalsList: BehaviorSubject<CreateModalInterface[]> = new BehaviorSubject<CreateModalInterface[]>([]);

  //#endregion

  //#region Public Methods

  public create(component: Type<any>, modalOptions?: any): string {
    const id = generateRandomString(6);
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(component);
    this.modalsList.next([...this.modalsList.value, { id, component: componentFactory, modalOptions }]);
    return id;
  }

  public destroy(id: string): void {
    const modal = this.modalsList.value.find(m => m.id === id);
    this.modalsList.value.splice(this.modalsList.value.indexOf(modal), 1);
    this.modalsList.next(this.modalsList.value);
  }

  //#endregion
}

export interface CreateModalInterface {
  id: string;
  component: ComponentFactory<any>;
  modalOptions?: {
    componentProps: Record<string, any>;
  };
}
