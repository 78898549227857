import { api, routes } from './routes';
import { version } from './version';

export const environment = {
  production: true,
  apiBaseUrl: 'https://api-dev.{subdomain}.unicorporativa.com',
  version: version,
  build: 'b61',
  config: {
    imgproxyUrl: 'https://us-east-1.imgproxy.ligafacens.com/',
    redirectToWhenAuthenticated: '/main/inicio',
    redirectToWhenUnauthenticated: '/entrar',
    lang: 'pt-PT',
    progressToMarkAsWatched: '0.7',
    supportEmail: '<dev-facchini>@email.com',
    facens: {
      loginUrl:
        'https://auth.facens.ligafacens.com/authorize?response_type=code&redirect_uri={redirectUri}&client_id={clientId}',
      clientId: '8aefb238-e129-4b06-aef0-0122c68e7771',
      redirectUrl: 'https://facens.dev.unicorporativa.com/oauth2',
      callback: '/oauth2/facens/callback?code={code}',
    },
  },
  routes,
  api,
  keys: {
    token: 'USER_TOKEN',
    user: 'USER_INFO',
    paymentInfoBackup: 'PAYMENT_INFO_BACKUP',
    isRegister: 'IS_REGISTER',
    forceSubdomain: '@uni/force-subdomain',
  },
  sentry: {
    dns: 'https://2a89cd8059e24ecebd3646eb8386553e@sentry.ligafacens.com/70',
    server: 'https://sentry.ligafacens.com/api',
    release: '1.0.0',
    tags: {
      framework: 'angular',
      provider: 'liga',
      type: 'web',
      name: 'liga.web.angular.unicorporativa-facchini',
    },
    feedback: {
      lang: 'pt-BR',
      title: 'Parece que estamos tendo alguns problemas.',
      subtitle: 'Nossa equipe foi notificada.',
      subtitle2: 'Se você quiser ajudar, conte-nos o que aconteceu abaixo.',
      labelName: 'Nome',
      labelEmail: 'E-mail',
      labelComments: 'O que aconteceu?',
      labelSubmit: 'Enviar',
      labelClose: 'Fechar',
      errorGeneric:
        'Ocorreu um erro desconhecido ao enviar o seu feedback. Por favor, tente novamente.',
      errorFormEntry:
        'Alguns campos são inválidos. Corrija os erros e tente novamente.',
      successMessage: 'Seu feedback foi enviado. Obrigado!',
    },
  },
};
