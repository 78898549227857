import { Injectable } from '@angular/core';

export enum ThemeType {
  dark = 'dark',
  default = 'default',
  engoflife = 'engoflife',
}

@Injectable({
  providedIn: 'root',
})
export class ThemeService {
  currentTheme: ThemeType = (localStorage.getItem('@storage/theme') as ThemeType) || ThemeType.dark;

  private reverseTheme(theme: string): ThemeType {
    return theme === ThemeType.dark ? ThemeType.default : ThemeType.dark;
  }

  private removeUnusedTheme(theme: ThemeType): void {
    document.documentElement.classList.remove(theme);
    const removedThemeStyle = document.getElementById(theme);
    if (removedThemeStyle) {
      document.head.removeChild(removedThemeStyle);
    }
  }

  private loadCss(href: string, id: string): Promise<Event> {
    return new Promise((resolve, reject) => {
      const style = document.createElement('link');
      style.rel = 'stylesheet';
      style.href = href;
      style.id = id;
      style.onload = resolve;
      style.onerror = reject;
      document.head.append(style);
    });
  }

  public loadTheme(firstLoad = true): Promise<Event> {
    const theme = this.currentTheme;
    if (firstLoad) {
      document.documentElement.classList.add(theme);
    }
    return new Promise<Event>((resolve, reject) => {
      this.loadCss(`${theme}.css`, theme).then(
        (e) => {
          if (!firstLoad) {
            document.documentElement.classList.add(theme);
          }
          this.removeUnusedTheme(this.reverseTheme(theme));
          resolve(e);
        },
        (e) => reject(e),
      );
    });
  }

  public toggleTheme(): Promise<Event> {
    if (this.currentTheme === ThemeType.engoflife)
      return;

    this.currentTheme = this.reverseTheme(this.currentTheme);

    localStorage.setItem('@storage/theme', this.currentTheme);

    return this.loadTheme(false);
  }

  public setTheme(theme: ThemeType, persist: boolean = false): Promise<Event> {
    this.currentTheme = theme;

    if (persist)
      localStorage.setItem('@storage/theme', theme);

    return this.loadTheme(false);
  }
}
