//#region Imports

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { nanoid } from 'nanoid';

import { EventTrackingInteractor } from '../../interactors/event-tracking/event-tracking.interactor';
import { ActionTrackingType } from '../../models/enums/action-tracking.type';
import { EventTrackingType } from '../../models/enums/event-tracking.type';

//#endregion

/**
 * A classe que representa o serviço que lida com o rastreamento de eventos na plataforma
 */
@Injectable({ providedIn: 'root' })
export class EventTrackingService {

  //#region Constructor

  /**
   * Construtor padrão
   */
  constructor(
    private readonly router: Router,
    private readonly interactor: EventTrackingInteractor,
  ) { }

  //#endregion

  //#region Public Properties

  /**
   * A identificação da sessão
   */
  public sessionUid: string = nanoid(16);

  //#endregion

  //#region Public Methods

  /**
   * Método que envia um novo evento para a API
   *
   * @param event O nome do evento
   * @param action O nome da ação
   * @param value Um valor a mais que será armazenado junto com o evento
   */
  public async sendEvent(event: EventTrackingType, action: ActionTrackingType, value?: string): Promise<boolean> {
    const { error } = await this.interactor.sendEvent(event, action, this.sessionUid, this.router.url, value);

    return !error;
  }

  //#endregion

}
