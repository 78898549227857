//#region Imports

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '../../services/auth/auth.service';

//#endregion

/**
 * A classe que representa o guard que lida com a autenticação do APP
 */
@Injectable({
  providedIn: 'root',
})
export class SetupAuthenticationGuard implements CanActivate {

  //#region Constructor

  /**
   * Construtor padrão
   */
  constructor(
    private readonly auth: AuthService,
  ) { }

  //#endregion

  /**
   * Método que diz se deve ativar a rota ou não
   */
  public async canActivate(__: ActivatedRouteSnapshot, _: RouterStateSnapshot) {
    await this.auth.setupAuthentication();

    return true;
  }
}
