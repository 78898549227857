//#region Imports

import { Injectable } from '@angular/core';

import { environment } from '../../../environments/environment';
import { ActionTrackingType } from '../../models/enums/action-tracking.type';
import { EventTrackingType } from '../../models/enums/event-tracking.type';
import { EventTrackingProxy } from '../../models/proxies/event-tracking.proxy';
import { AsyncResult } from '../../modules/http-async/models/async-result';
import { HttpAsyncService } from '../../modules/http-async/services/http-async.service';

//#endregion

@Injectable({ providedIn: 'root' })
export class EventTrackingInteractor {

  //#region Constructor

  constructor(
    private readonly http: HttpAsyncService,
  ) {}

  //#endregion

  //#region Public Methods

  public async sendEvent(
    event: EventTrackingType,
    action: ActionTrackingType,
    sessionUid: string,
    url: string,
    value?: string
  ): Promise<AsyncResult<EventTrackingProxy>> {
    return this.http.post(environment.api.eventTracking.create, {
      event,
      action,
      sessionUid,
      url,
      value,
    });
  }

  //#endregion

}
