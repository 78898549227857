//#region Imports

import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { InstanceProxy } from '../../models/proxies/instance.proxy';
import { RefreshTokenInterceptor } from '../../modules/http-async/interceptors/refresh-token-interceptor.service';
import { HttpAsyncService } from '../../modules/http-async/services/http-async.service';

//#endregion
@Injectable({
  providedIn: 'root',
})
export class InstanceService {
  //#region Constructor

  constructor(
    protected readonly router: Router,
    protected readonly http: HttpAsyncService,
    protected readonly title: Title
  ) {}

  //#endregion

  //#region Private Properties

  private currentInstanceSubject: BehaviorSubject<InstanceProxy | null> =
    new BehaviorSubject<InstanceProxy>(null);

  public get currentInstance(): InstanceProxy | null {
    return this.currentInstanceSubject.value;
  }

  public getCurrentInstance$(): Observable<InstanceProxy | null> {
    return this.currentInstanceSubject.asObservable();
  }

  //#endregion

  //#region Public Methods

  public async initialize(): Promise<void> {
    const subdomain = this.getCurrentSubdomain();

    await this.loadInstanceBySubdomain(subdomain);

    (window as any).__uniGetInstance = () => this.currentInstanceSubject;
    (window as any).__uniSetCurrentInstanceBySubdomain = (domain) =>
      this.loadInstanceBySubdomain(domain);
    (window as any).__uniClearForcedSubdomain = () =>
      localStorage.removeItem(environment.keys.forceSubdomain);
    (window as any).__uniForceSubdomain = (domain) => {
      localStorage.setItem(environment.keys.forceSubdomain, domain);

      this.loadInstanceBySubdomain(domain);
    };
  }

  public getCurrentSubdomain(): string {
    return 'facchini';
  }

  //#endregion

  //#region Private Methods

  private async loadInstanceBySubdomain(subdomain: string): Promise<void> {
    const { error, success } = await this.http.get<InstanceProxy>(
      `/instances/${subdomain}`,
      {
        headers: {
          [RefreshTokenInterceptor.DISABLE_HEADER]: 'true',
        },
      }
    );

    if (error)
      return void (await this.router.navigateByUrl('/instance-not-found'));

    this.currentInstanceSubject.next(success);

    this.setupInstance(success);
  }

  private setupInstance(instance: InstanceProxy): void {
    this.title.setTitle(`UniCorp - ${instance.fantasyName}`);

    const faviconIcon: HTMLLinkElement | null =
      document.querySelector('link[rel="icon"]');

    if (!faviconIcon) return;

    faviconIcon.href = instance.faviconUrl;
  }

  //#endregion
  
}
