//#region Imports

import { NgModule } from '@angular/core';
import { Route, RouterModule, Routes } from '@angular/router';

import { environment } from '../environments/environment';
import { AuthenticateGuard } from './guards/authenticate/authenticate.guard';
import { SetupAuthenticationGuard } from './guards/authenticate/setup-authentication.guard';

//#endregion

/**
 * As configurações para as rotas que NÃO precisam de autenticação
 */
export const unAuthenticatedRoute: Partial<Route> = {
  canActivate: [SetupAuthenticationGuard, AuthenticateGuard],
  data: { routeToRedirect: environment.config.redirectToWhenAuthenticated, unprotectedRoute: true }
};

/**
 * As configurações para as rotas que PRECISAM de autenticação
 */
export const authenticatedRoute: Partial<Route> = {
  canActivate: [SetupAuthenticationGuard, AuthenticateGuard],
  data: { routeToRedirect: environment.config.redirectToWhenUnauthenticated, protectedRoute: true }
};

const routes: Routes = [
  { path: '', redirectTo: 'main/inicio', pathMatch: 'full' },
  { path: 'main', loadChildren: () => import('./pages/main/main.module').then(m => m.MainModule), ...authenticatedRoute },
  { path: 'login', redirectTo: 'entrar' },
  { path: 'entrar', loadChildren: () => import('./pages/login/login.module').then(m => m.LoginModule), ...unAuthenticatedRoute },
  { path: 'oauth2', loadChildren: () => import('./pages/oauth/oauth.module').then(m => m.OauthModule), ...unAuthenticatedRoute },
  { path: 'register', redirectTo: 'registrar' },
  { path: 'registrar', loadChildren: () => import('./pages/register/register.module').then(m => m.RegisterModule), ...unAuthenticatedRoute },
  { path: '**', redirectTo: 'main/inicio' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      paramsInheritanceStrategy: 'always',
      relativeLinkResolution: 'legacy',
      anchorScrolling: "enabled",
      onSameUrlNavigation: "reload",
      scrollPositionRestoration: "enabled",
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
