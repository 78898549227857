//#region Imports

import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { LoginPayload } from '../../models/payloads/login.payload';
import { NewPasswordPayload } from '../../models/payloads/new-password.payload';
import { CreateUserPayload } from '../../models/payloads/register.payload';
import { ForgotPasswordProxy } from '../../models/proxies/forgot-password.proxy';
import { TokenProxy } from '../../models/proxies/token.proxy';
import { UserProxy } from '../../models/proxies/user.proxy';
import { VerifyResetPasswordCode } from '../../models/proxies/verify-reset-password-code';
import { AsyncResult } from '../../modules/http-async/models/async-result';
import { HttpAsyncService } from '../../modules/http-async/services/http-async.service';

//#endregion

@Injectable({
  providedIn: 'root',
})
export class AuthInteractor {

  //#region Constructor

  constructor(
    private readonly http: HttpAsyncService,
  ) {
  }

  //#endregion

  //#region Auth Methods

  public async performOrganizationLogin(payload: LoginPayload): Promise<AsyncResult<TokenProxy>> {
    return await this.http.post<TokenProxy>(environment.routes.auth, payload);
  }

  public async performNewtonLogin(payload: LoginPayload): Promise<AsyncResult<TokenProxy>> {
    return await this.http.post<TokenProxy>(environment.routes.newtonLogin, payload);
  }

  public async performFacensLogin(code: string): Promise<AsyncResult<any>> {
    return await this.http.get<any>(environment.config.facens.callback.replace('{code}', code));
  }

  //#endregion

  //#region User Methods

  public async createUser(payload: CreateUserPayload): Promise<AsyncResult<UserProxy>> {
    return await this.http.post<UserProxy>('/users', { ...payload, lang: environment.config.lang });
  }

  public async getMe(): Promise<AsyncResult<UserProxy>> {
    return await this.http.get<UserProxy>(environment.routes.userMe);
  }

  public async updateMe(userId: string, payload: Partial<UserProxy>): Promise<AsyncResult<UserProxy>> {
    return await this.http.put(environment.api.users.update.replace('{userId}', userId), payload);
  }

  public async forgotPassword(email: string): Promise<AsyncResult<ForgotPasswordProxy>> {
    return await this.http.post<ForgotPasswordProxy>(environment.routes.forgotPasswordEmail.replace('{email}', email), {});
  }

  public async sendVerificationCodeToServer(code: string): Promise<AsyncResult<VerifyResetPasswordCode>> {
    let url = environment.routes.forgotPasswordCode;
    url = url.replace('{passwordCode}', code);
    return await this.http.get(url);
  }

  public async changePasswordForgotPassword(payload: NewPasswordPayload): Promise<AsyncResult<void>> {
    let url = environment.routes.resetPassword;
    url = url.replace('{resetPasswordCode}', payload.resetPasswordCode);
    return await this.http.post(url, { newPassword: payload.newPassword });
  }

  public async canUserUpdatePasswordByEmail(email: string): Promise<AsyncResult<boolean>> {
    const url = environment.routes.canUserUpdatePassword;
    return await this.http.post<boolean>(url.replace('{email}', email), {});
  }

  //#endregion

}
