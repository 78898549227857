export const routes = {
  auth: '/auth/local',
  newtonLogin: '/newton',
  refreshToken: '/auth/refresh',
  user: '/users',
  canUserUpdatePassword: '/users/password/forgot/front/{email}',
  updateUserPassword: '/users/password/change',
  changePassword: '/users/password/change',
  checkModuleProgress: '/users/progress/check-module/{moduleId}',
  deleteMessage: '/lessons/{lessonId}/messages/{messageId}',
  deleteUser: '/users/{userId}',
  forgotPasswordCode: '/users/password/verify/{passwordCode}',
  forgotPasswordEmail: '/users/password/forgot/email/{email}',
  getAnswerQuestion: '/quizzes/questions/answers/{quizAnswerId}',
  getLessonWithActiveMedias: '/lessons/{lessonId}?activeMedia=true',
  getManyQuizzes: '/quizzes?s={searchParams}',
  getOneCourse: '/courses/{courseId}',
  getOneLesson: '/lessons/{lessonId}',
  getOneQuiz: '/quizzes/{quizId}',
  getQuizAnswer: '/users/quizzes/answers?s={searchParams}&limit={limit}',
  getQuizAnswerDocument: '/quizzes/{quizId}/answer/document',
  getQuizQuestions: '/quizzes/questions?quizId={quizId}',
  getUsersNotifications: '/users/notifications?join[0]=notification',
  lesson: '/lessons',
  markProgress: '/users/progress/lessons',
  markQuizAnswer: '/users/quizzes/answers',
  messages:
    '/lessons/messages?page=1&limit=2000&sort=createdAt,DESC&join[0]=sender&s={searchParams}',
  registerUserForNewsByUser: '/news/releases/user/{userId}',
  resetModuleProgress: '/users/progress/reset/{moduleId}',
  resetPassword: '/users/password/reset/{resetPasswordCode}',
  subjectOngoing: '/courses/ongoing',
  track: '/tracks',
  updateMessage: '/lessons/{lessonId}/messages/{messageId}',
  updateUserNotification: '/users/notifications/{notificationId}',
  userLessonProgress: '/users/progress?s={searchParams}&limit=2000',
  userLessonProgressVideoTime:
    '/users/progress/lessons/{lessonId}/time/{videoTime}/total/',
  userLessonRatings: '/lessons/ratings?s={searchParams}&limit=2000',
  userMe: '/users/me?join=address',
  userVerifyCode: '/users/verify/{code}',
};

export const api = {
  track: {
    get: '/tracks?s={searchParams}&page={page}&limit={limit}',
    rating: '/tracks/ratings',
    ratingWithId: '/courses/ratings/{id}',
    ratingsFromTrack: '/tracks/ratings?s={searchParams}',
    category: {
      listPaginated: '/tracks/categories',
    },
  },
  courses: {
    list: '/courses?s={searchParams}&page={page}&limit={limit}&skipAdminCheck=true',
    myCourses:
      '/courses/my-courses?s={searchParams}&page={page}&limit={limit}&skipAdminCheck=true&onlyPending={onlyPending}',
    toExpire:
      '/courses/to-expire?s={searchParams}&page={page}&limit={limit}&skipAdminCheck=true',
    otherCourses:
      '/courses/other-courses?s={searchParams}&page={page}&limit={limit}&skipAdminCheck=true',
    get: '/courses/{courseId}?join[0]=modules&join[1]=modules.lessons&join[2]=teams&join[3]=modules.quizzes&join[4]=category',
    purchased: '/courses/purchased',
    featured:
      '/courses?s={searchParams}&sort=featuredLevel,DESC&limit=100&skipAdminCheck=true',
    subscribedStudents: '/courses/{courseId}/students/info',
    categories: {
      list: '/courses/categories',
      get: '/courses/categories/{id}',
      update: '/courses/categories/{id}',
      feed: '/courses/categories/feed?page={page}&limit={limit}&numberOfCourses={numberOfCourses}',
    },
    ratings: {
      list: '/courses/ratings',
      update: '/courses/ratings/{id}',
      ratingsFromCourse: '/courses/ratings?s={searchParams}',
    },
    waitlist: {
      list: '/courses/waitlists?s={searchParams}',
      create: '/courses/waitlists',
      delete: '/courses/waitlists/{id}',
    },
    contents: {
      list: '/courses/contents?s={searchParams}&limit={limit}',
    },
    teams: {
      getOne: '/courses/teams/{teamId}',
      list: '/courses/teams?s={searchParams}&limit={limit}',
    },
    usersTeams: {
      listSubscribedByCourseAndUserId:
        '/users/courses/teams?s={searchParams}&limit={limit}',
    },
    external: {
      sendDiscountLink: '/courses/{courseId}/external/discount',
    },
  },
  users: {
    get: '/users/{userId}?join[0]=newsRelease',
    update: '/users/{userId}',
    verifyStudent: '/users/{userId}/student/verify',
    isEmailUsed: '/users/emails/{email}/used',
    address: '/users/addresses',
    addressById: '/users/addresses/{id}',
  },
  userCourses: {
    byUserId: '/users/courses?s={searchParams}',
  },
  userAnswers: {
    listPaginated:
      '/users/quizzes/answers?s={searchParams}&page={page}&limit={limit}',
    listPaginatedWithJoins:
      '/users/quizzes/answers?s={searchParams}&page={page}&limit={limit}&join[0]=question&join[1]=answer',
  },
  quizzes: {
    listPaginated: '/quizzes?s={searchParams}&page={page}&limit={limit}',
    listPaginatedWithJoins:
      '/quizzes?s={searchParams}&page={page}&limit={limit}&join[0]=questions',
  },
  lessons: {
    rate: '/lessons/ratings',
  },
  medias: {
    get: '/lessons/medias?s={searchParams}&limit=1000&join[0]=lesson',
  },
  modules: {
    list: '/modules?s={searchParams}',
  },
  eventTracking: {
    create: '/events/trackings',
  },
  certificates: {
    downloadCertificate: '/users/{userId}/courses/{subjectId}/certificates',
    getByCertificateId: '/users/certificates/identification/{certificateId}',
    getCertificates: '/users/certificates',
    getOrCreate: '/users/certificates/get-or-create/{courseId}',
    verifyCertificateRequisites: '/users/certificates/verify/{courseId}',
  },
  payments: {
    getAllPayments: '/users/courses/payments',
    createPayment: '/users/courses/{courseId}/teams/{courseTeamId}/payments',
    list: '/users/courses/payments?join=course&s={searchParams}&sort=createdAt,DESC&limit=2000',
    cancelPayment: '/users/courses/payments/{paymentId}/cancel',
  },
  annotations: {
    create: '/users/lessons/annotations',
    update: '/users/lessons/annotations/{id}',
    remove: '/users/lessons/annotations/{id}',
    listPaginatedWithSearch:
      '/users/lessons/annotations?s={searchParams}&limit={limit}&page={page}',
    getByAnnotationId: '/users/lessons/annotations/{id}',
  },
};
