//#region Imports

import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import fileSize from 'filesize';

import { Observable, Subject } from 'rxjs';
import { BaseUrlInterceptor } from '../interceptors/base-url.interceptor';
import { BearerTokenInterceptor } from '../interceptors/bearer-token.interceptor';
import { HttpAsyncHeadersInterceptor } from '../interceptors/http-async-headers.interceptor';

import { takeUntil } from 'rxjs/operators';
import { AsyncResult } from '../models/async-result';
import { DefaultOptions, ExtraOptions } from '../models/http-options';

//#endregion

/**
 * A classe que representa um serviço responsável por lidar com as chamadas assíncronas em um Endpoint
 */
@Injectable()
export class HttpAsyncService {
  //#region Construtor

  /**
   * Construtor padrão
   */
  constructor(protected readonly http: HttpClient) {}

  //#endregion

  //#region Public Properties

  public showError: boolean = true;

  public errorMessage: string = '';

  //#endregion

  //#region Private Properties

  /**
   * O evento emitido ao ocorrer um erro com a requisição
   */
  private readonly onHttpError: Subject<HttpErrorResponse> =
    new Subject<HttpErrorResponse>();

  private cancelRequest$: Subject<boolean> = new Subject<boolean>();

  //#endregion

  //#region Public Methods

  /**
   * Método que retorna o evento chamado ao ocorrer um erro com a chamada API
   */
  public getOnHttpError$(): Observable<HttpErrorResponse> {
    return this.onHttpError.asObservable();
  }

  /**
   * Método que a referência para o cliente HTTP nativo
   */
  public getHttpClient(): HttpClient {
    return this.http;
  }

  //#endregion

  //#region Private Methods

  /**
   * Converte um resultado para AsyncResult para quando der certo
   *
   * @param result O resultado obtido
   */
  private success<T>(result: T): AsyncResult<T> {
    return {
      success: result,
    };
  }

  /**
   * Encapsula o erro no AsyncResult
   *
   * @param error O erro enviado pelo servidor
   */
  private error<T>(error: HttpErrorResponse): AsyncResult<T> {
    this.onHttpError.next(error);

    return {
      error,
    };
  }

  //#endregion

  //#region Async Restfull Methods

  /**
   * Envia uma requisição com o método GET de forma assincrona
   *
   * @param url Url para a requisição. Obs: Ele já é automaticamente combinado com a url base
   * @param options As opções a mais que você pode passar para as requisições
   */
  public async get<T>(
    url: string,
    options?: DefaultOptions
  ): Promise<AsyncResult<T>> {
    return await this.http
      .get<T>(url, options)
      .toPromise()
      .then((data: T) => {
        return this.success(data);
      })
      .catch((error: HttpErrorResponse) => {
        return this.error<T>(error);
      })
      .then<AsyncResult<T>>((result: AsyncResult<T>) => {
        return result;
      });
  }

  public async cancelableGETRequest<T>(url: string): Promise<AsyncResult<T>> {
    return this.http
      .get<T>(url)
      .pipe(takeUntil(this.cancelRequest$))
      .toPromise()
      .then((res) => {
        return this.success(res);
      })
      .catch((error: HttpErrorResponse) => {
        return this.error<T>(error);
      });
  }

  public cancelRequest() {
    this.cancelRequest$.next(true);
    this.cancelRequest$.complete();
  }

  /**
   * Envia uma requisição com o método POST
   *
   * @param url Url para a requisição. Obs: Ele já é automaticamente combinado com a url base
   * @param payload Informações a serem enviadas para o servidor
   * @param options As opções a mais que você pode passar para as requisições
   */
  public async post<T>(
    url: string,
    payload: object,
    options?: ExtraOptions
  ): Promise<AsyncResult<T>> {
    return await this.http
      .post<T>(url, payload, options)
      .toPromise()
      .then((data: T) => {
        return this.success(data);
      })
      .catch((error: HttpErrorResponse) => {
        return this.error<T>(error);
      })
      .then<AsyncResult<T>>((result: AsyncResult<T>) => {
        return result;
      });
  }

  /**
   * Envia uma requisição com o método PUT
   *
   * @param url Url para a requisição. Obs: Ele já é automaticamente combinado com a url base
   * @param payload Informações a serem enviadas para o servidor
   * @param options As opções a mais que você pode passar para as requisições
   */
  public async put<T>(
    url: string,
    payload: object,
    options?: ExtraOptions
  ): Promise<AsyncResult<T>> {
    return await this.http
      .put<T>(url, payload, options)
      .toPromise()
      .then((data: T) => {
        return this.success(data);
      })
      .catch((error: HttpErrorResponse) => {
        return this.error<T>(error);
      })
      .then<AsyncResult<T>>((result: AsyncResult<T>) => {
        return result;
      });
  }

  /**
   * Envia uma requisição com o método DELETE
   *
   * @param url Url para a requisição. Obs: Ele já é automaticamente combinado com a url base
   * @param options As opções a mais que você pode passar para as requisições
   */
  public async delete<T>(
    url: string,
    options?: ExtraOptions
  ): Promise<AsyncResult<T>> {
    return await this.http
      .delete<T>(url, options)
      .toPromise()
      .then((data: T) => {
        return this.success(data);
      })
      .catch((error: HttpErrorResponse) => {
        return this.error<T>(error);
      })
      .then<AsyncResult<T>>((result: AsyncResult<T>) => {
        return result;
      });
  }

  /**
   * Método que retorna o tamanho do arquivo sem a necessidade de fazer o download dele
   */
  public async getFileSize(url: string): Promise<AsyncResult<string>> {
    return await this.http
      .get(url, {
        headers: {
          Range: 'bytes=0-0',
          [BearerTokenInterceptor.DISABLE_HEADER]: 'true',
          [BaseUrlInterceptor.DISABLE_HEADER]: 'true',
          [HttpAsyncHeadersInterceptor.DISABLE_HEADER]: 'true',
        },
        responseType: 'arraybuffer',
        observe: 'response',
      })
      .toPromise()
      .then((data) => {
        const size = Number(data.headers.get('content-range')?.split('/')[1]);

        return this.success(fileSize(size));
      })
      .catch((error: HttpErrorResponse) => {
        return this.error(error);
      })
      .then<AsyncResult<any>>((result: AsyncResult<any>) => {
        return result;
      });
  }

  //#endregion
}
