import { RolesEnum } from '../models/enums/roles.enum';
import { UserProxy } from '../models/proxies/user.proxy';
import { emailRegex, vimeoUrlRegex } from './constants';

/**
 * Método que espera alguns segundos para continuar executando
 */
export async function delay(milliseconds: number): Promise<void> {
  return await new Promise((resolve) => setTimeout(resolve, milliseconds));
}

/**
 * Método que retorna o valor com um zero a esquerda se necessário
 *
 * @param num O número que será formatado
 */
export function getLeftPad(num: number): string {
  return num <= 9 ? `0${num}` : `${num}`;
}

/**
 * Método que retorna o valor sem referências
 *
 * @param mock O valor a ser transformado
 */
export function noRef<T>(mock: T): T {
  if (['string', 'number'].includes(typeof mock)) return mock;

  return JSON.parse(JSON.stringify(mock));
}

/**
 * Método que diz se o usuário é um usuário de administrador
 *
 * @param user As informações do usuário
 */
export function isAdminUser(user?: UserProxy): boolean {
  return user && user.permissions && hasRole(user.permissions, RolesEnum.ADMIN);
}

/**
 * Método que diz se o usuário é um usuário de professor
 *
 * @param user As informações do usuário
 */
export function isTeacherUser(user?: UserProxy): boolean {
  return (
    user && user.permissions && hasRole(user.permissions, RolesEnum.TEACHER)
  );
}

/**
 * Método que diz se o usuário é um usuário de administrador
 *
 * @param user As informações do usuário
 */
export function isNormalUser(user?: UserProxy): boolean {
  return (
    user &&
    user.permissions &&
    hasRole(user.permissions, RolesEnum.USER) &&
    !isAdminUser(user) &&
    !isTeacherUser(user)
  );
}

export function isSafetyTechnician(user?: UserProxy): boolean {
  return (
    user &&
    user.permissions &&
    hasRole(user.permissions, RolesEnum.SAFETY_TECHNICIAN)
  );
}

/**
 * Método que diz se o usuário é um usuário de administrador
 *
 * @param roles As permissões de um usuário
 * @param targetRole A permissão que você está procurando
 */
export function hasRole(roles: string, targetRole: string): boolean {
  return !!roles && roles.split('|').some((role) => role === targetRole);
}

/**
 * Método que retorna a mensagem erro
 *
 * @param error As informações de erro
 */
export function getErrorMessage({ status, error, message }: any): string {
  if (status === 503)
    return (
      error?.message ||
      'Ocorreu um erro inesperado, por favor, experimente atualizar a página e tentar novamente.'
    );

  if (status >= 500 && status <= 599)
    return 'Ocorreu um erro inesperado, por favor, experimente atualizar a página e tentar novamente.';

  if (!error) {
    if (Array.isArray(message)) return message[0];

    return (
      message ||
      'Ocorreu um erro inesperado, por favor, experimente atualizar a página e tentar novamente.'
    );
  }

  if (!Array.isArray(error.message)) {
    if (typeof error.message === 'string' && error.message.includes('Cannot'))
      return 'A rota especificada não foi encontrada, por favor, contate os administradores se o erro persistir.';

    return (
      error.message ||
      'Ocorreu um erro inesperado, por favor, experimente atualizar a página e tentar novamente.'
    );
  }

  if (error.message.every((message) => typeof message === 'string'))
    return error.message[0];

  // @ts-ignore
  return error.message
    .map(({ constraints }) => (constraints && Object.values(constraints)) || [])
    .reduce((acc, actual) => [...acc, ...actual] as string[])[0];
}

/***
 * Método que transforma segundos em horas
 *
 * @param timeInSeconds O tempo total em segundos
 */
export function convertSecondsToHour(timeInSeconds: number): string {
  const hours = Math.floor(timeInSeconds / (60 * 60));
  let minutes = Math.floor(timeInSeconds / 60);
  let slackMinutes = Math.floor(minutes / 60);
  let restHours = timeInSeconds % (60 * 60);

  minutes = minutes - 60 * slackMinutes;
  restHours %= 60;
  const seconds = Math.ceil(restHours);

  return `${hours}:${minutes}:${seconds}`;
}

/***
 * Método que transforma o horário em segundos
 *
 * @param time O tempo no formato (HH:MM:SS)
 */
export function convertHourToSeconds(time: string): number {
  let currentTime = time.split(':'); // split it at the colons

  return +currentTime[0] * 60 * 60 + +currentTime[1] * 60 + +currentTime[2];
}

export function getFirstLetterOfFirstAndLastNames(userName: string): string[] {
  userName = userName.trim();
  const nameFirstLetter = userName[0].toUpperCase();

  const splitName = userName.split(' ');

  let lastnameFirstLetter = splitName[splitName.length - 1].toUpperCase();
  lastnameFirstLetter = [...lastnameFirstLetter][0];

  return [nameFirstLetter, lastnameFirstLetter];
}

export function getVimeoIdByUrl(url: string): string {
  const match = url.match(vimeoUrlRegex);

  if (match) return match[1].split('/')[1];

  throw new Error('Esse não é um link do Vimeo, tente novamente!');
}

export function shuffle<T>(array: Array<T>): Array<T> {
  if (!array) return [];

  let currentIndex: number = array.length;
  let randomIndex: number = 0;

  while (currentIndex > 0) {
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex],
      array[currentIndex],
    ];
  }

  return array;
}

export function isValidEmail(email: string): boolean {
  if (!email) return false;

  if (!emailRegex.test(email)) return false;

  return true;
}
