//#region Imports

import { registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import ptBr from '@angular/common/locales/pt';
import { APP_INITIALIZER, ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router } from '@angular/router';
import { Network } from '@ionic-native/network/ngx';
import { IonicStorageModule } from '@ionic/storage';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NZ_I18N, pt_BR } from 'ng-zorro-antd/i18n';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzNotificationModule } from 'ng-zorro-antd/notification';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NgxMaskModule } from 'ngx-mask';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LangInterceptor } from './interceptors/lang.interceptor';
import { ModalRootModule } from './modals/modal-root/modal-root.module';
import { HttpAsyncModule } from './modules/http-async/http-async.module';
import { BaseUrlInterceptor } from './modules/http-async/interceptors/base-url.interceptor';
import { BearerTokenInterceptor } from './modules/http-async/interceptors/bearer-token.interceptor';
import { HttpAsyncHeadersInterceptor } from './modules/http-async/interceptors/http-async-headers.interceptor';
import { RefreshTokenInterceptor } from './modules/http-async/interceptors/refresh-token-interceptor.service';
import { RetryInterceptor } from './modules/http-async/interceptors/retry.interceptor';
import { IsOfflineInterceptor } from './modules/network/interceptors/is-offline.interceptor';
import { NetworkModule } from './modules/network/network.module';
import { createErrorHandler } from './modules/sentry/error-handler';
import { TraceService } from './modules/sentry/tracing';
import { EventTrackingService } from './services/event-tracking/event-tracking.service';
import { InstanceService } from './services/instance/instance.service';
import { AppInitializerProvider } from './services/theme/theme.initializer';

//#endregion

registerLocaleData(ptBr, 'pt-BR');

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    ModalRootModule,
    IonicStorageModule.forRoot({
      dbKey: '__engdb',
      driverOrder: ['sqlite', 'indexeddb', 'localstorage'],
    }),
    HttpAsyncModule.withConfig({
      baseUrl: environment.apiBaseUrl,
      defaultHeaders: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      bearerTokenKey: environment.keys.token,
    }),
    NetworkModule,
    FormsModule,
    HttpClientModule,
    NzIconModule,
    NzNotificationModule,
    NzModalModule,
    NzTableModule,
    NzDropDownModule,
    ModalRootModule,
    NgxMaskModule.forRoot(),
  ],
  providers: [
    AppInitializerProvider,
    EventTrackingService,
    Network,
    {
      provide: ErrorHandler,
      useValue: createErrorHandler({
        showDialog: false,
        logErrors: !environment.production,
        dialogOptions: environment.sentry.feedback,
      }),
    },
    {
      provide: TraceService,
      deps: [Router],
      useValue: undefined,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [TraceService],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      multi: true,
      deps: [InstanceService],
      useFactory: (instance: InstanceService) => () => instance.initialize(),
    },
    { provide: LOCALE_ID, useValue: 'pt-BR' },
    { provide: HTTP_INTERCEPTORS, useClass: IsOfflineInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: BaseUrlInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: HttpAsyncHeadersInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: BearerTokenInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: RefreshTokenInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: RetryInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: LangInterceptor, multi: true },
    { provide: NZ_I18N, useValue: pt_BR },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
