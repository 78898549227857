import { AfterViewInit, Component, ComponentRef, HostListener, Input, OnDestroy, ViewChild, ViewContainerRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { CreateModalInterface, ModalService } from '../../services/modal/modal.service';

@Component({
  selector: 'uni-modal-root',
  templateUrl: './modal-root.component.html',
  styleUrls: ['./modal-root.component.scss'],
})
export class ModalRootComponent implements AfterViewInit, OnDestroy {

  //#region constructor

  constructor(
    private readonly modalService: ModalService,
  ) {}

  //#endregion

  //#region public properties

  @ViewChild('modalRender', { read: ViewContainerRef })
  public modalRender: ViewContainerRef;

  @Input()
  public shouldCloseOnBackdropClick: boolean = true;

  public isOpenModal: boolean = false;

  public currentModal: CreateModalInterface;

  public componentRef: ComponentRef<any>;

  private modalListSubscription: Subscription;

  //#endregion

  //#region public methods

  public ngAfterViewInit(): void {
    this.modalListSubscription = this.modalService.modalsList.subscribe(modalList => {
      this.updateModals(modalList);
    });
  }

  @HostListener('document:keydown', ['$event'])
  onKeyDownHandler(event: KeyboardEvent) {
    if (event.key === "Escape")
      this.destroy();
  }

  public updateModals(modalList) {
    if (modalList[0])
      this.currentModal = { ...modalList[0] };

    if (!!this.componentRef && modalList.indexOf(this.currentModal) === -1) {
      this.closeOpenAnimation(false);

      setTimeout(() => {
        this.componentRef.destroy();
        this.currentModal = null;
        this.componentRef = null;
        this.modalRender.clear();
      }, 500);

      return;
    }

    if (!this.currentModal) return;

    this.componentRef = this.modalRender.createComponent(this.currentModal.component);
    this.closeOpenAnimation(true);
    this.componentRef.instance.modalId = this.currentModal.id;

    this.getModalProps(this.currentModal.modalOptions.componentProps);
  }

  public getModalProps(props: Record<string, any>): void {
    const keys = Object.keys(props);

    if (keys.length <= 0)
      return;

    if(props['shouldCloseOnBackdropClick'] === false)
      this.shouldCloseOnBackdropClick = false;

    keys.forEach((item) => {
      this.componentRef.instance[item] = props[item];
    });
  }

  public ngOnDestroy() {
    this.modalListSubscription.unsubscribe();
  }

  public closeOpenAnimation(isOpening: boolean): void {
    this.isOpenModal = isOpening;
  }

  public destroy(): void {
    if (!this.currentModal) return;

    if (this.shouldCloseOnBackdropClick)
      this.modalService.destroy(this.currentModal.id);
  }

  //#endregion

}
