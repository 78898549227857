//#region Imports

import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy, Inject } from '@angular/core';
import { BehaviorSubject, fromEvent, merge, Observable, Subscription } from 'rxjs';
import { mapTo, startWith } from 'rxjs/operators';
import { DOCUMENT } from '@angular/common';

//#endregion

@Injectable()
export class NetworkService implements OnDestroy {

  //#region Constructor

  constructor(
    @Inject(DOCUMENT) private document: Document,
    protected readonly http: HttpClient,
  ) {
    this.window = this.document.defaultView;

    this.hasInternetConnectionSubscription = this.hasInternetConnection$()
      .subscribe(isOnline => this.isOnlineSubject.next(isOnline));
  }

  //#endregion

  //#region Private Properties

  private readonly hasInternetConnectionSubscription: Subscription;

  private readonly isOnlineSubject = new BehaviorSubject<boolean>(true);

  private window: Window;

  //#endregion

  //#region LifeCycle Events

  public ngOnDestroy(): void {
    this.hasInternetConnectionSubscription.unsubscribe();
    this.isOnlineSubject.unsubscribe();
  }

  //#endregion

  //#region Public Methods

  public isOnline(): boolean {
    return this.isOnlineSubject.getValue();
  }

  public isOnline$(): Observable<boolean> {
    return this.isOnlineSubject.asObservable();
  }

  //#endregion

  //#region Private Methods

  private hasInternetConnection$(): Observable<boolean> {
    return merge(
      fromEvent(this.window, 'offline').pipe(mapTo(false)),
      fromEvent(this.window, 'online').pipe(mapTo(true)),
    ).pipe(
      startWith(this.window.navigator.onLine),
    );
  }

  //#endregion

}
