import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from '@sentry/browser';
import { Integrations } from '@sentry/tracing';

import { AppModule } from './app/app.module';
import { routingInstrumentation } from './app/modules/sentry/tracing';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

Sentry.init({
  dsn: environment.sentry.dns,
  integrations: [
    ...Sentry.defaultIntegrations,
    new Integrations.BrowserTracing({
      traceXHR: true,
      traceFetch: true,
      tracingOrigins: ['localhost', environment.sentry.server],
      routingInstrumentation,
    }),
  ],
  release: environment.sentry.release,
  sampleRate: .1,
  tracesSampleRate: 0,
  tracesSampler: () => 0,
  debug: !environment.production,
  environment: environment.production ? 'production' : 'development',
  beforeSend(event, hint) {
    if (!event.exception)
      return event;

    // Note: issue with double entries during http exceptions: https://github.com/getsentry/sentry-javascript/issues/2169
    // Note: issue with a second entry not being set correctly (as a non-error): https://github.com/getsentry/sentry-javascript/issues/2292#issuecomment-554932519
    const isNonErrorException = event.exception.values[0].value.startsWith('Non-Error exception captured');
    if (isNonErrorException) {
      if (!event.extra.__serialized__)
        return null;

      // @ts-ignore
      let realErrMsg = event.extra.__serialized__.error ? event.extra.__serialized__.error.message : null;

      // @ts-ignore
      realErrMsg = realErrMsg || event.extra.__serialized__.message;

      // this is a useless error message that masks the actual error.  Lets try to set it properly
      event.exception.values[0].value = realErrMsg;
      event.message = realErrMsg;
    }
    return event;
  },
});

Sentry.setTags(environment.sentry.tags);

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
