//#region Imports

import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '../../environments/environment';

//#endregion

/**
 * A classe que representa o interceptor que adiciona uma linguagem para todas as requisições
 */
@Injectable()
export class LangInterceptor implements HttpInterceptor {

  //#region Public Static Properties

  /**
   * O header para lidar com a linguagem da API
   */
  public static readonly LANG_HEADER: string = 'X-API-Lang';

  /**
   * O header que pode ser passado para desativar esse interceptor
   */
  public static readonly DISABLE_HEADER: string = 'X-Disabled-Lang';

  //#endregion

  //#region Public Methods

  /**
   * Método que é executado para interceptar a requisição e realizar alguma operação
   */
  public intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (!req.headers.get(LangInterceptor.DISABLE_HEADER))
      req = req.clone({
        headers: req.headers.set(LangInterceptor.LANG_HEADER, environment.config.lang),
      });
    else {
      req = req.clone({
        headers: req.headers.delete(LangInterceptor.DISABLE_HEADER),
      });
    }

    return next.handle(req);
  }

  //#endregion

}
