//#region Imports

import { Component } from '@angular/core';
import { NzIconService } from 'ng-zorro-antd/icon';
import { ThemeService, ThemeType } from './services/theme/theme.service';
import {
  advancedSearchIcon,
  avatarIcon,
  birthDateIcon,
  bookIcon,
  certificateIcon,
  checkIcon,
  darkModeIcon,
  domestikaIcon,
  durationIcon,
  engIcon,
  facensIcon,
  facensRoundIcon,
  homeIcon,
  interrogationCheckIcon,
  interrogationIcon,
  learnIcon,
  learnIconOutline,
  newtonIcon,
  newtonRoundIcon,
  notificationIcon,
  notificationWhiteIcon,
  notUserIcon,
  notUserWhiteIcon,
  passwordIcon,
  passwordWhiteIcon,
  pauseIcon,
  paymentIcon,
  paymentWhiteIcon,
  penIcon,
  peopleIcon,
  photoIcon,
  photoWhiteIcon,
  playIcon,
  privacyPolicyIcon,
  privacyPolicyWhiteIcon,
  rankingIcon,
  starFilledIcon,
  starIcon,
  termsOfUseIcon,
  termsOfUseWhiteIcon,
  userIcon,
  userWhiteIcon,
  whiteModeIcon,
} from './utils/icons';

//#endregion

@Component({
  selector: 'uni-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  //#region Constructor

  constructor(
    private readonly theme: ThemeService,
    private readonly nzIcon: NzIconService
  ) {
    this.initializeTheme();

    this.nzIcon.addIconLiteral('eng:advanced_search', advancedSearchIcon);
    this.nzIcon.addIconLiteral('eng:white_mode', whiteModeIcon);
    this.nzIcon.addIconLiteral('eng:dark_mode', darkModeIcon);
    this.nzIcon.addIconLiteral('eng:learn', learnIcon);
    this.nzIcon.addIconLiteral('eng:learn_outline', learnIconOutline);
    this.nzIcon.addIconLiteral('eng:star', starIcon);
    this.nzIcon.addIconLiteral('eng:star_filled', starFilledIcon);
    this.nzIcon.addIconLiteral('eng:duration', durationIcon);
    this.nzIcon.addIconLiteral('eng:people', peopleIcon);
    this.nzIcon.addIconLiteral('eng:book', bookIcon);
    this.nzIcon.addIconLiteral('eng:avatar', avatarIcon);
    this.nzIcon.addIconLiteral('eng:home', homeIcon);
    this.nzIcon.addIconLiteral('eng:ranking', rankingIcon);
    this.nzIcon.addIconLiteral('eng:logo', engIcon);
    this.nzIcon.addIconLiteral('eng:facens_round', facensRoundIcon);
    this.nzIcon.addIconLiteral('eng:newton_round', newtonRoundIcon);
    this.nzIcon.addIconLiteral('eng:certificate', certificateIcon);
    this.nzIcon.addIconLiteral('eng:photo', photoIcon);
    this.nzIcon.addIconLiteral('eng:photoWhite', photoWhiteIcon);
    this.nzIcon.addIconLiteral('eng:user', userIcon);
    this.nzIcon.addIconLiteral('eng:userWhite', userWhiteIcon);
    this.nzIcon.addIconLiteral('eng:lock', passwordIcon);
    this.nzIcon.addIconLiteral('eng:lockWhite', passwordWhiteIcon);
    this.nzIcon.addIconLiteral('eng:payment', paymentIcon);
    this.nzIcon.addIconLiteral('eng:paymentWhite', paymentWhiteIcon);
    this.nzIcon.addIconLiteral('eng:termsOfUse', termsOfUseIcon);
    this.nzIcon.addIconLiteral('eng:termsOfUseWhite', termsOfUseWhiteIcon);
    this.nzIcon.addIconLiteral('eng:privacyPolicy', privacyPolicyIcon);
    this.nzIcon.addIconLiteral(
      'eng:privacyPolicyWhite',
      privacyPolicyWhiteIcon
    );
    this.nzIcon.addIconLiteral('eng:notification', notificationIcon);
    this.nzIcon.addIconLiteral('eng:notificationWhite', notificationWhiteIcon);
    this.nzIcon.addIconLiteral('eng:notUser', notUserIcon);
    this.nzIcon.addIconLiteral('eng:notUserWhite', notUserWhiteIcon);
    this.nzIcon.addIconLiteral('eng:birthDate', birthDateIcon);
    this.nzIcon.addIconLiteral('eng:pen', penIcon);
    this.nzIcon.addIconLiteral('eng:check', checkIcon);
    this.nzIcon.addIconLiteral('eng:pause', pauseIcon);
    this.nzIcon.addIconLiteral('eng:play', playIcon);
    this.nzIcon.addIconLiteral('eng:interrogation', interrogationIcon);
    this.nzIcon.addIconLiteral(
      'eng:interrogationCheck',
      interrogationCheckIcon
    );
    this.nzIcon.addIconLiteral('eng:facens', facensIcon);
    this.nzIcon.addIconLiteral('eng:domestika', domestikaIcon);
    this.nzIcon.addIconLiteral('eng:newton', newtonIcon);
  }

  //#endregion

  //#region Public Methods

  public async initializeTheme(): Promise<void> {
    await this.theme.setTheme(ThemeType.dark, true);
  }

  //#endregion
}
