/**
 * As roles permitidas nessa aplicação
 */
export enum RolesEnum {
  /**
   * A Role normal de usuário anonimo
   */
  ANONYMOUS = 'anonymous',

  /**
   * A Role normal de usuário
   */
  USER = 'user',

  /**
   * A role de um professor
   */
  TEACHER = 'teacher',

  /**
   * A role de administrador
   */
  ADMIN = 'admin',

  /**
   * Um usuário ser permissão
   */
  NONE = 'none',

  SAFETY_TECHNICIAN = 'safety-technician',
}
