//#region Imports

import { Injectable } from '@angular/core';

import * as Sentry from '@sentry/browser';
import { BehaviorSubject, Observable } from 'rxjs';

import { HttpErrorResponse } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { AuthInteractor } from '../../interactors/auth/auth.interactor';
import { LoginPayload } from '../../models/payloads/login.payload';
import { NewPasswordPayload } from '../../models/payloads/new-password.payload';
import { CreateUserPayload } from '../../models/payloads/register.payload';
import { UserProxy } from '../../models/proxies/user.proxy';
import { getErrorMessage } from '../../utils/functions';
import { StorageService } from '../storage/storage.service';

//#endregion

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  //#region Constructor

  constructor(
    private readonly interactor: AuthInteractor,
    private readonly storage: StorageService
  ) {}

  //#endregion

  //#region Events

  private readonly onChangeAuthentication: BehaviorSubject<
    UserProxy | undefined
  > = new BehaviorSubject<UserProxy | undefined>(void 0);

  //#endregion

  //#region Auth Methods

  public async setupAuthentication(): Promise<void> {
    const { error, success } = await this.storage.getItem<UserProxy>(
      environment.keys.user
    );

    if (error || !success) return;

    Sentry.setUser({ id: success.id?.toString(), username: success.username });

    if (this.onChangeAuthentication.getValue()?.id === success.id) return;

    this.onChangeAuthentication.next(success);
  }

  public isAuthenticated(): boolean {
    return !!this.onChangeAuthentication.getValue();
  }

  public async logout(): Promise<void> {
    await this.storage.clear();

    this.onChangeAuthentication.next(void 0);
  }

  public async performLogin(
    payload: LoginPayload
  ): Promise<[boolean, string?]> {
    Sentry.setUser({
      username: payload.username,
      email: payload.username,
    });

    const { error, success: token } =
      await this.interactor.performOrganizationLogin(payload);

    if (error) return [false, getErrorMessage(error)];

    await this.storage.setItem(environment.keys.token, token);

    const me = await this.getMe();

    if (!me) {
      await this.storage.remove(environment.keys.token);

      return [
        false,
        'Não foi possível obter as informações do seu usuário, se o erro persistir, contate os administradores.',
      ];
    }

    return [true];
  }

  public async performFacensLogin(code: string): Promise<[boolean, string?]> {
    const { error, success: token } = await this.interactor.performFacensLogin(
      code
    );

    if (error) return [false, getErrorMessage(error)];

    await this.storage.setItem(environment.keys.token, token);

    const me = await this.getMe();

    if (!me) {
      await this.storage.remove(environment.keys.token);

      return [
        false,
        'Não foi possível obter as informações do seu usuário, se o erro persistir, contate os administradores.',
      ];
    }

    return [true];
  }

  public async performNewtonLogin(
    payload: LoginPayload
  ): Promise<[boolean, string?]> {
    Sentry.setUser({
      username: payload.username,
      cpf: payload.username,
    });

    const { error, success: token } = await this.interactor.performNewtonLogin(
      payload
    );

    if (error) return [false, getErrorMessage(error)];

    await this.storage.setItem(environment.keys.token, token);

    const me = await this.getMe();

    if (!me) {
      await this.storage.remove(environment.keys.token);

      return [
        false,
        'Não foi possível obter as informações do seu usuário, se o erro persistir, contate os administradores.',
      ];
    }

    return [true];
  }

  public async getMe(): Promise<UserProxy> {
    const { error, success } = await this.interactor.getMe();

    if (error) return;

    await this.storage.setItem<UserProxy>(environment.keys.user, success);

    this.onChangeAuthentication.next(success);

    return success;
  }

  public async updateMe(
    payload: Partial<UserProxy>
  ): Promise<[boolean, string?]> {
    const user = this.getCurrentUser();

    if (!user)
      return [
        false,
        'Você não está logado na plataforma, logo, não é possível atualizar as suas informações.',
      ];

    const { error, success } = await this.interactor.updateMe(user.id, payload);

    if (error) {
      const errorMessage: string = this.handleDuplicateEmailError(error);
      return [false, errorMessage];
    }

    const me = await this.getMe();

    if (!me)
      return [
        false,
        'Não foi possível recuperar as informações atualizadas, tente atualizar a página.',
      ];

    return [true];
  }

  public async registerUser(
    payload: CreateUserPayload
  ): Promise<[boolean, string?]> {
    Sentry.setUser({
      username: `${payload.name} ${payload.lastName}`,
      email: payload.email,
    });

    payload.email = payload.email.toLowerCase();

    const { error } = await this.interactor.createUser({
      ...payload,
      name: `${payload.name} ${payload.lastName}`,
    });

    if (error) return [false, getErrorMessage(error)];

    const isSuccess = await this.performLogin({
      password: payload.password,
      username: payload.email,
    });

    if (!isSuccess)
      return [
        false,
        'Seu usuário foi criado mas não foi possível autenticar automaticamente, por favor, vá em login e entre.',
      ];

    await this.getMe();

    return [true];
  }

  public async forgotPassword(email: string): Promise<[boolean, string]> {
    const { error, success } = await this.interactor.forgotPassword(email);

    if (error) return [false, getErrorMessage(error)];

    if (success.isEmailSent)
      return [success.isEmailSent, 'O e-mail foi enviado com sucesso!'];

    return [
      success.isEmailSent,
      'O e-mail não foi enviado, verifique o e-mail e tente novamente!',
    ];
  }

  public async sendVerificationCodeToServer(
    code: string
  ): Promise<[boolean, string?]> {
    const { success, error } =
      await this.interactor.sendVerificationCodeToServer(code);

    if (error) return [false, getErrorMessage(error)];

    return [success.isPasswordCodeCorrect];
  }

  public async changePasswordForgotPassword(
    newPasswordPayload: NewPasswordPayload
  ): Promise<[boolean, string]> {
    const { error } = await this.interactor.changePasswordForgotPassword(
      newPasswordPayload
    );

    if (error) return [false, getErrorMessage(error)];

    return [true, 'A senha foi alterada com sucesso!'];
  }

  //#endregion

  //#region User Methods

  public getCurrentUser(): UserProxy {
    return this.onChangeAuthentication.getValue() as UserProxy;
  }

  public getCurrentUser$(): Observable<UserProxy> {
    return this.onChangeAuthentication.asObservable();
  }

  public async canUserUpdatePasswordByEmail(
    email: string
  ): Promise<[boolean, string?]> {
    const { error, success } =
      await this.interactor.canUserUpdatePasswordByEmail(email);

    if (error || !success) return [false, getErrorMessage(error)];

    return [success];
  }

  //#endregion

  //#region private methods

  private handleDuplicateEmailError(error: HttpErrorResponse): string {
    if (error.error.exception.message?.includes('idx_users_email_and_instance'))
      return 'Já existe um usuário com este e-mail, por favor, escolha outro!';

    return getErrorMessage(error);
  }

  //#endregion
}
